import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    const cookies = document.cookie.split('; ')
    const accessTokenCookie = cookies.find(cookie => {
      const [name] = cookie.split('=');
      return name.endsWith("accessToken");
    })
    const accessToken = accessTokenCookie?.split("=")[1];

    async function fetchUserInfo() {
      const response = await fetch("https://sbxb.auth.us-east-1.amazoncognito.com/oauth2/userInfo", {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        }
      });
      const responseJson = await response.json();
      setIsAuthenticated(true)
      setCurrentUser(responseJson)
    }

    if (accessToken) {
      fetchUserInfo()
    } else {
      setIsAuthenticated(false)
    }
  }, [])

  const userMessage = isAuthenticated
    ? <div>
        <p>Logged in as {currentUser.username}</p>
        <pre>
          {JSON.stringify(currentUser)}
        </pre>
        <p>
          <a href="https://sbxb.vikred.people.aws.dev/signOut">
            Sign Out
          </a>
        </p>
      </div>
    : <p>
      <a href="https://sbxb.vikred.people.aws.dev/signIn">
        Sign In
      </a>
    </p>;

  return (
    <div className="App">
      <h1>SBXB</h1>
      { userMessage }
      <p>
        Go to <a href="https://onboard.sbxb.vikred.people.aws.dev">https://onboard.sbxb.vikred.people.aws.dev</a>
      </p>
    </div>
  );
}

export default App;
